.search-bar {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
}

.search-bar form {
  display: flex;
  flex-direction: column;
}
